import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import { FeaturedPost, Layout, PostMeta } from '../../components'

import './blog-post.scss'

const BlogPostTemplate = ({ data, location, pageContext }) => {
  const { title, siteUrl } = data.site.siteMetadata
  const { post, defaultImage } = data
  const hasFeaturedImage = !!post.frontmatter.featuredImage
  let relatedPost
  try {
    relatedPost = data.relatedPost.edges[0].node
  } catch (error) {
    //
  }
  const ogImage = hasFeaturedImage
    ? post.frontmatter.featuredImage.childImageSharp.sizes.src
    : defaultImage.childImageSharp.sizes.src

  return (
    <Layout location={ location }>
      <Helmet
        htmlAttributes={ { lang: 'en' } }
        meta={ [
          { name: 'description', content: post.frontmatter.excerpt },
          { property: 'og:locale', content: 'en_US' },
          { property: 'og:type', content: 'article' },
          { property: 'og:title', content: post.frontmatter.title },
          { property: 'og:description', content: post.frontmatter.excerpt },
          { property: 'og:url', content: `${siteUrl}${post.fields.slug}` },
          { property: 'og:site_name', content: title },
          { property: 'article:publisher', content: 'https://signalsciences.com' },
          { property: 'article:section', content: pageContext.category },
          { property: 'og:image', content: `${siteUrl}${ogImage}` },
          { property: 'twitter:card', content: 'summary_large_image' },
          { property: 'twitter:description', content: post.frontmatter.excerpt },
          { property: 'twitter:title', content: post.frontmatter.title },
          { property: 'twitter:site', content: '@signalsciences' },
          { property: 'twitter:image', content: `${siteUrl}${ogImage}` },
          { property: 'twitter:creator', content: '@signalsciences' },
        ] }
        link={ [{ rel: 'canonical', href: `${siteUrl}${post.fields.slug}` }] }
        title={ `${post.frontmatter.title} | ${title}` }
      />
      <article className='PostContent'>
        {
          hasFeaturedImage && (
            <React.Fragment>
              <div className='PageContainer'>
                <div className='PostMetaMobileBefore'>
                  <PostMeta post={ post } mobileBefore />
                </div>
              </div>
              <Img
                className='PostCoverImage'
                sizes={ post.frontmatter.featuredImage.childImageSharp.sizes }
                alt={ post.frontmatter.featuredImageAltText }
              />
              <div className='PageContainer'>
                <header className='PostCover PostCover--FeaturedImage'>
                  <h1 className='PostTitle'>
                    <span className='PostTitleHighlight'>
                    { post.frontmatter.title }
                    </span>
                  </h1>
                  <div className='PostExcerpt'>{ post.frontmatter.excerpt }</div>
                </header>
                <div className='PostMetaMobileAfter'>
                  <PostMeta post={ post } mobileAfter />
                </div>
                <main className='PostBody'>
                  <div className='PostLayout'>
                    <aside className='PostMetaDesktop--FeaturedImage'>
                      <PostMeta post={ post } />
                    </aside>
                    <main>
                      <div dangerouslySetInnerHTML={ { __html: post.html } } />
                    </main>
                  </div>
                </main>
              </div>
            </React.Fragment>
          )
        }
        {
          !hasFeaturedImage && (
            <React.Fragment>
              <div className='PageContainer'>
                <div className='PostLayout'>
                  <div className='PostMetaMobileBefore'>
                    <PostMeta post={ post } mobileBefore />
                  </div>
                  <aside className='PostMetaDesktop--NoFeaturedImage'>
                    <PostMeta post={ post } />
                  </aside>
                  <main>
                    <header className='PostCover'>
                      <h1 className='PostTitleNoImage'>{ post.frontmatter.title }</h1>
                      <div className='PostExcerpt'>{ post.frontmatter.excerpt }</div>
                    </header>
                    <div className='PostMetaMobileAfter'>
                      <PostMeta post={ post } mobileAfter />
                    </div>
                    <div className='PostBody'>
                      <div dangerouslySetInnerHTML={ { __html: post.html } } />
                    </div>
                  </main>
                </div>
              </div>
            </React.Fragment>
          )
        }
      </article>
      { relatedPost && (
        <div className='PageContainer'>
          <div className='PostRelated'>
            <p className='GridHeader'>
              Read more in { pageContext.category }
            </p>
            <FeaturedPost post={ relatedPost } nextPost='true' />
          </div>
        </div>
      ) }
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $category: String!) {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
    defaultImage: file(relativePath: { eq: "default.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 760) {
          ...GatsbyImageSharpSizes
        }
      }
    }
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      html
      fields {
        slug
        readingTime {
          text
        }
      }
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
        category
        author {
          id
          bio
          url
        }
        excerpt
        featuredImage {
          childImageSharp {
            sizes(maxWidth: 1440) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        featuredImageAltText
      }
    }
    relatedPost: allMarkdownRemark(
      filter: {
        fields: { slug: { ne: $slug } }
        frontmatter: { category: { eq: $category } }
      }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
            author {
              id
              bio
              url
            }
            category
            excerpt
            featuredImage {
              childImageSharp {
                sizes(maxWidth: 760) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
            featuredImageAltText
          }
        }
      }
    }
  }
`
